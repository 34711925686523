import { lazy, useEffect } from "react";
import PrivacyPolicyContent from "../../content/PrivacyPolicyContent.json";
const Container = lazy(() => import("../../common/Container"));
const ScrollToTop = lazy(() => import("../../common/ScrollToTop"));

const PrivacyPolicy = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Container>
      <ScrollToTop />
      <div className="privacy-policy">
        <h1>{PrivacyPolicyContent.title}</h1>
        <p>{PrivacyPolicyContent.text.introduction}</p>

        <h4>{PrivacyPolicyContent.text.information_collection.title}</h4>
        {PrivacyPolicyContent.text.information_collection.text.map((item, index) => (
          <p key={index}>{item}</p>
        ))}

        <h4>{PrivacyPolicyContent.text.use_of_information.title}</h4>
        {PrivacyPolicyContent.text.use_of_information.text.map((item, index) => (
          <p key={index}>{item}</p>
        ))}

        <h4>{PrivacyPolicyContent.text.data_sharing.title}</h4>
        {PrivacyPolicyContent.text.data_sharing.text.map((item, index) => (
          <p key={index}>{item}</p>
        ))}

        <h4>{PrivacyPolicyContent.text.data_security.title}</h4>
        <p>{PrivacyPolicyContent.text.data_security.text}</p>

        <h4>{PrivacyPolicyContent.text.user_rights.title}</h4>
        {PrivacyPolicyContent.text.user_rights.text.map((item, index) => (
          <p key={index}>{item}</p>
        ))}

        <h4>{PrivacyPolicyContent.text.changes_to_policy.title}</h4>
        <p>{PrivacyPolicyContent.text.changes_to_policy.text}</p>

        <h4>{PrivacyPolicyContent.text.contact_us.title}</h4>
        <p>{PrivacyPolicyContent.text.contact_us.text}</p>
      </div>
    </Container>
  );
};

export default PrivacyPolicy;
