import { lazy, Suspense } from "react";
import { Switch, Route } from "react-router-dom";
import Footer from "../components/Footer";
import Header from "../components/Header";
import routes from "./config";
import { Styles } from "../styles/styles";
import PrivacyPolicy from "../pages/Home/PrivacyPolicy";
import { TermsAndConditions } from "../pages/Home/TermsAndConditions";
import RefundsAndCancellations from "../pages/Home/RefundsAndCancellations";
import AppointmentsPolicy from "../pages/Home/AppointmentsPolicy";
import ContactUs from "../pages/Home/ContactUs";

const Router = () => {
  return (
    <Suspense fallback={null}>
      <Styles />
      <Header />
      <Switch>
        {routes.map((routeItem) => {
          return (
            <Route
              key={routeItem.component}
              path={routeItem.path}
              exact={routeItem.exact}
              component={lazy(() => import(`../pages/${routeItem.component}`))}
            />
          );
        })}
        <Route path="/privacy" component={PrivacyPolicy} />
        <Route path="/terms" component={TermsAndConditions} />
        <Route path="/cancellations-and-refunds" component={RefundsAndCancellations} />
        <Route path="/appointments-policy" component={AppointmentsPolicy} />
        <Route path="/contact-us" component={ContactUs} />
      </Switch>
      <Footer />
    </Suspense>
  );
};

export default Router;
