import { lazy, useEffect } from "react";
import RefundsAndCancellationsContent from "../../content/RefundsAndCancellations.json";
const Container = lazy(() => import("../../common/Container"));
const ScrollToTop = lazy(() => import("../../common/ScrollToTop"));

const RefundsAndCancellations = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Container>
      <ScrollToTop />
      <div className="cancellations-and-refunds">
        <h1>{RefundsAndCancellationsContent.title}</h1>
        <p>{RefundsAndCancellationsContent.introduction}</p>

        <h4>{RefundsAndCancellationsContent.cancellation_policy.title}</h4>
        {RefundsAndCancellationsContent.cancellation_policy.content.map((item, index) => (
          <div key={index}>
            <p>{item.point}</p>
            {item.details && <p>{item.details}</p>}
          </div>
        ))}

        <h4>{RefundsAndCancellationsContent.refund_policy.title}</h4>
        {RefundsAndCancellationsContent.refund_policy.content.map((item, index) => (
          <div key={index}>
            <p>{item.point}</p>
            {item.details && <p>{item.details}</p>}
          </div>
        ))}

        <h4>{RefundsAndCancellationsContent.process.title}</h4>
        {RefundsAndCancellationsContent.process.content.map((item, index) => (
          <p key={index}>{item.step}</p>
        ))}

        <h4>{RefundsAndCancellationsContent.contact_info.title}</h4>
        <p>{RefundsAndCancellationsContent.contact_info.content}</p>
      </div>
    </Container>
  );
};

export default RefundsAndCancellations;
