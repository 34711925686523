import { lazy, useEffect } from "react";
import AppointmentsPolicyContent from "../../content/AppointmentsPolicy.json";
const Container = lazy(() => import("../../common/Container"));
const ScrollToTop = lazy(() => import("../../common/ScrollToTop"));

const AppointmentsPolicy = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Container>
      <ScrollToTop />
      <div className="appointments-policy">
        <h1>{AppointmentsPolicyContent.title}</h1>
        <p>{AppointmentsPolicyContent.introduction}</p>

        <h4>{AppointmentsPolicyContent.appointment_policy.title}</h4>
        <p>{AppointmentsPolicyContent.appointment_policy.content}</p>

        <h4>{AppointmentsPolicyContent.notification_policy.title}</h4>
        {AppointmentsPolicyContent.notification_policy.content.map((item, index) => (
          <p key={index}>{item.point}</p>
        ))}

        <h4>{AppointmentsPolicyContent.support_info.title}</h4>
        <p>{AppointmentsPolicyContent.support_info.content}</p>
      </div>
    </Container>
  );
};

export default AppointmentsPolicy;
